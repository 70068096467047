.gradient {
  &-timer {
    background: linear-gradient(45deg, #80dffa, #08da86);
  }
  &-getting-started {
    background: linear-gradient(0deg, #8cacff, #750adf);
  }
  &-animation {
    transform-origin: center left;
    animation: gradient 5s linear alternate-reverse infinite;
  }
  &-timeline {
    &-1 {
      background: linear-gradient(103.24deg, #7611e0, #8ca8fe);
    }
    &-2 {
      background: linear-gradient(103.24deg, #ed8016, #ffc403);
    }
    &-3 {
      background: linear-gradient(252.58deg, #780de0 0%, #e986fe 108.78%);
    }
    &-4 {
      background: linear-gradient(
        252.58deg,
        #e35bf0 0%,
        #5f78d8 56.65%,
        #18fdc7 108.78%
      );
    }
    &-5 {
      background: linear-gradient(252.58deg, #88deff 0%, #05d982 108.78%);
    }
  }
  &-bonus {
    &-1 {
      background: linear-gradient(
        90deg,
        #06d984,
        #38deb6c4 39.62%,
        #70e3ef80 73.71%,
        #88deff33
      );
    }
    &-2 {
      background: linear-gradient(
        90deg,
        #750bdf0f,
        #7c39e880 39.62%,
        #8477f4 73.71%,
        #8ca7fe
      );
    }
    &-3 {
      background: linear-gradient(
        90deg,
        #ec7e16,
        #f39a0ec7 39.62%,
        #fbb60687 73.71%,
        #fec40278
      );
    }
  }
  &-heading-text {
    background-image: linear-gradient(
      90deg,
      rgb(136, 222, 255),
      rgb(15, 219, 140)
    );
  }
  &-prizes-text {
    background-image: linear-gradient(
      90deg,
      rgb(121, 15, 224),
      rgb(234, 136, 254)
    );
  }
  &-prize {
    &-2nd {
      background-image: linear-gradient(
        45deg,
        rgb(117, 14, 224),
        rgb(139, 165, 254)
      );
    }
    &-1st {
      background-image: linear-gradient(
        45deg,
        rgb(130, 223, 252),
        rgb(16, 221, 144)
      );
    }
    &-3rd {
      background-image: linear-gradient(
        45deg,
        rgb(236, 126, 21),
        rgb(254, 194, 2)
      );
    }
  }
}

.color {
  &-prize {
    &-2nd {
      background: rgb(117, 14, 224);
    }
    &-1st {
      background: rgb(1, 193, 113);
    }
    &-3rd {
      background: rgb(236, 126, 21);
    }
  }
}

.header--scrolled {
  @apply px-8 py-6;
}

@keyframes gradient {
  0% {
    transform: scaleX(1) translateX(0%);
  }
  100% {
    transform: scaleX(2) translateX(-50%);
  }
}

.arrow {
  &-small {
    @apply relative h-1 w-64 -translate-x-0.5 border-b border-dashed border-[#3B3BFF] lg:block hidden;
    &::after {
      content: "";
      @apply absolute top-1/2 -right-1 rotate-45 h-1 w-1 border-t border-r border-[#3B3BFF];
    }
  }
  &-end {
    @apply relative h-[calc(50%_-_0.5rem)] translate-y-1 w-1 left-[12.5%] border-l border-dashed border-[#3B3BFF] lg:block hidden;
    &::after {
      content: "";
      @apply absolute right-1/2 -bottom-[4px] rotate-[135deg] h-1 w-1 border-t border-r border-[#3B3BFF];
    }
  }
  &-begin {
    @apply relative lg:block hidden h-1/2 w-[75%] left-1/2 -translate-x-1/2 border-r border-dashed border-[#3B3BFF];
  }
  &-large {
    @apply relative lg:block hidden h-1 w-[75%] left-1/2 -translate-y-1/2 -translate-x-1/2 border-b border-dashed border-[#3B3BFF];
  }
  &-text {
    @apply hidden text-xl text-black lg:block font-termina absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-4;
  }
}

.events {
  @apply cursor-pointer hover:border-blue-600 bg-[#0a0a0a] border-2 border-transparent w-full py-3 lg:py-6 px-4 lg:px-8 grid grid-cols-8 lg:grid-cols-9 items-center gap-2;
  &:first-child {
    @apply rounded-t-3xl;
  }
  &:last-child {
    @apply rounded-b-3xl;
  }
}

.btn-hover {
  @apply relative;
  & a {
    @apply transition-transform block relative hover:-translate-x-1 hover:-translate-y-1;
  }
  & span {
    @apply z-[-1] rounded transition-transform block absolute bg-[#0B0BFF] top-0 left-0 w-full h-full;
  }
}

.subscribe-input-hidden {
  @apply hidden;
}

.subscribe-button-success {
  @apply w-full bg-[#00ef8b] text-[black];
}

.toast {
  border: 0.1rem solid hsla(157, 87%, 12%, 0.5) !important;
  background: linear-gradient(
    to right,
    hsl(155, 92%, 5%) 0%,
    hsl(155, 88%, 5%) 100%
  ) !important;
  box-shadow: 0 4px 12px #00000036 !important;
  border-radius: 0.5rem !important;
  padding: 1rem 1.25rem !important;
  font-size: 1rem !important;
  button {
    margin-left: 0.675rem;
  }
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
