.gradient-timer {
  background: linear-gradient(45deg, #80dffa, #08da86);
}

.gradient-getting-started {
  background: linear-gradient(0deg, #8cacff, #750adf);
}

.gradient-animation {
  transform-origin: 0;
  animation: 5s linear infinite alternate-reverse gradient;
}

.gradient-timeline-1 {
  background: linear-gradient(103.24deg, #7611e0, #8ca8fe);
}

.gradient-timeline-2 {
  background: linear-gradient(103.24deg, #ed8016, #ffc403);
}

.gradient-timeline-3 {
  background: linear-gradient(252.58deg, #780de0 0%, #e986fe 108.78%);
}

.gradient-timeline-4 {
  background: linear-gradient(252.58deg, #e35bf0 0%, #5f78d8 56.65%, #18fdc7 108.78%);
}

.gradient-timeline-5 {
  background: linear-gradient(252.58deg, #88deff 0%, #05d982 108.78%);
}

.gradient-bonus-1 {
  background: linear-gradient(90deg, #06d984, #38deb6c4 39.62%, #70e3ef80 73.71%, #88deff33);
}

.gradient-bonus-2 {
  background: linear-gradient(90deg, #750bdf0f, #7c39e880 39.62%, #8477f4 73.71%, #8ca7fe);
}

.gradient-bonus-3 {
  background: linear-gradient(90deg, #ec7e16, #f39a0ec7 39.62%, #fbb60687 73.71%, #fec40278);
}

.gradient-heading-text {
  background-image: linear-gradient(90deg, #88deff, #0fdb8c);
}

.gradient-prizes-text {
  background-image: linear-gradient(90deg, #790fe0, #ea88fe);
}

.gradient-prize-2nd {
  background-image: linear-gradient(45deg, #750ee0, #8ba5fe);
}

.gradient-prize-1st {
  background-image: linear-gradient(45deg, #82dffc, #10dd90);
}

.gradient-prize-3rd {
  background-image: linear-gradient(45deg, #ec7e15, #fec202);
}

.color-prize-2nd {
  background: #750ee0;
}

.color-prize-1st {
  background: #01c171;
}

.color-prize-3rd {
  background: #ec7e15;
}

.header--scrolled {
  padding: 1.5rem 2rem;
}

@keyframes gradient {
  0% {
    transform: scaleX(1)translateX(0%);
  }

  100% {
    transform: scaleX(2)translateX(-50%);
  }
}

.arrow-small {
  height: .25rem;
  width: 16rem;
  --tw-translate-x: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-style: dashed;
  border-bottom-width: 1px;
  border-color: rgb(59 59 255 / var(--tw-border-opacity));
  display: none;
  position: relative;
}

@media (min-width: 1024px) {
  .arrow-small {
    display: block;
  }
}

.arrow-small:after {
  content: "";
  height: .25rem;
  width: .25rem;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-right-width: 1px;
  border-color: rgb(59 59 255 / var(--tw-border-opacity));
  position: absolute;
  top: 50%;
  right: -.25rem;
}

.arrow-end {
  height: calc(50% - .5rem);
  width: .25rem;
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-style: dashed;
  border-left-width: 1px;
  border-color: rgb(59 59 255 / var(--tw-border-opacity));
  display: none;
  position: relative;
  left: 12.5%;
}

@media (min-width: 1024px) {
  .arrow-end {
    display: block;
  }
}

.arrow-end:after {
  content: "";
  height: .25rem;
  width: .25rem;
  --tw-rotate: 135deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-right-width: 1px;
  border-color: rgb(59 59 255 / var(--tw-border-opacity));
  position: absolute;
  bottom: -4px;
  right: 50%;
}

.arrow-begin {
  height: 50%;
  width: 75%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-style: dashed;
  border-right-width: 1px;
  border-color: rgb(59 59 255 / var(--tw-border-opacity));
  display: none;
  position: relative;
  left: 50%;
}

@media (min-width: 1024px) {
  .arrow-begin {
    display: block;
  }
}

.arrow-large {
  height: .25rem;
  width: 75%;
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-style: dashed;
  border-bottom-width: 1px;
  border-color: rgb(59 59 255 / var(--tw-border-opacity));
  display: none;
  position: relative;
  left: 50%;
}

@media (min-width: 1024px) {
  .arrow-large {
    display: block;
  }
}

.arrow-text {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: Termina, sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (min-width: 1024px) {
  .arrow-text {
    display: block;
  }
}

.events {
  width: 100%;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
  border-width: 2px;
  border-color: #0000;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  align-items: center;
  gap: .5rem;
  padding: .75rem 1rem;
  display: grid;
}

.events:hover {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

@media (min-width: 1024px) {
  .events {
    grid-template-columns: repeat(9, minmax(0, 1fr));
    padding: 1.5rem 2rem;
  }
}

.events:first-child {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.events:last-child {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.btn-hover {
  position: relative;
}

.btn-hover a {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: relative;
}

.btn-hover a:hover {
  --tw-translate-x: -.25rem;
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.btn-hover span {
  z-index: -1;
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(11 11 255 / var(--tw-bg-opacity));
  border-radius: .25rem;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.subscribe-input-hidden {
  display: none;
}

.subscribe-button-success {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(0 239 139 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.toast {
  background: linear-gradient(to right, #01180f 0%, #02180f 100%) !important;
  border: .1rem solid #04392580 !important;
  border-radius: .5rem !important;
  padding: 1rem 1.25rem !important;
  font-size: 1rem !important;
  box-shadow: 0 4px 12px #00000036 !important;
}

.toast button {
  margin-left: .675rem;
}

.full-width {
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  left: 50%;
  right: 50%;
}

/*# sourceMappingURL=index.190e449c.css.map */
